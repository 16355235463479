import React, { useContext, useEffect } from "react";
import Item from "./Item";
import Activity from "../../common/charts/Activity";
import { useModal } from "../../../context/ModalContext";
import { PageTitleContext } from "../../../context/PageTitleContext";
import TableRowSelection from "../../common/tables/TableRowSelection";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from "../../../api/slices/dashboardSlice";
import { fetchMembers } from "../../../api/services/memberService";
import { fetchTasksDashboard } from "../../../api/slices/completedTasksDashboardSlice";
import { fetchRoles } from "../../../api/slices/rolesSlice";

import Loader from "../../common/Loader";
import {
  encryptAesKeyWithRsa,
  encryptJSONWithAes,
  generateAesKey,
} from "../../../utils/generateKey";
import apiService from "../../../api/services/apiService";

const MyDashboard = () => {
  //Initialize modal
  const { openModal } = useModal();

  const dispatch = useDispatch();
  const { dashboardInfo, loadingDashboard } = useSelector(
    (state) => state.dashboard
  );
  const { completedTasksData, loadingDashboard2 } = useSelector(
    (state) => state.completedTask
  );
  const { membersData, loadingMembers } = useSelector((state) => state.member);

  useEffect(() => {
    {
      //get completed tasks
      dispatch(fetchTasksDashboard());
      dispatch(fetchRoles());
      dispatch(fetchDashboard());
      if (!membersData) {
        dispatch(fetchMembers());
      }
    }
  }, [membersData]);

  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle("Dashboard");

  //ADD CHECK FOR ERRORS?? RTK QUIERIES FOR DATA FETCHING

  //Check if data fetching is pedding or if there is no data, in both cases
  if (
    loadingMembers ||
    !membersData ||
    loadingDashboard ||
    !dashboardInfo ||
    loadingDashboard2
  ) {
    console.log("LOADING ODJE" + loadingMembers);

    return <Loader />;
  }
  // --------------------------------------- Table data ---------------------------------------

  const headerNamesMapper = {
    id: "ID",
    firstName: "Ime",
    lastName: "Prezime",
    roleName: "Rola",
    active: "Status",
  };

  // Display names for headers
  const headers = ["id", "firstName", "lastName", "roleName", "active"];

  //Trigger this function in case that deletation of team member is confirmed
  // const confirmDelete = (item) => {
  //   console.log("Confirm" + item);
  // };

  //Hardcoded values for user roles
  const options = [
    { value: 1, label: "Administrator" },
    { value: 2, label: "Koordinator" },
  ];

  setPageTitle("Dashboard");

  const podaci = ` {"test": "test", "proba": "proba" }`;

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary); // Konvertuje binarni string u Base64
  }

  const saljiPodatke = async () => {
    const aesKey = await generateAesKey();
    const publicKey = localStorage.getItem("serverPubKey");

    const encText = await encryptJSONWithAes(podaci, aesKey);
    const base64EncText = arrayBufferToBase64(encText);
    console.log("ectext", encText);
    const encryptedAesKey = await encryptAesKeyWithRsa(aesKey, publicKey);
    const podaci2 = [
      {
        data: base64EncText,
        key: encryptedAesKey,
      },
    ];

    const response = await apiService.postData(
      "health/test/decodeSentData",
      podaci2[0]
    );
    console.log("odg", response);
  };

  return (
    <div className="flex flex-col w-full h-full p-4 ">
      {/* First row */}
      <div className="flex flex-row w-full">
        <div className="flex w-full max-lg:flex-col">
          <div className="w-1/4 max-lg:w-full">
            <Item
              title="Započeti projekti"
              content={dashboardInfo?.projectCount}
            />
          </div>
          <div className="w-1/4 max-lg:w-full">
            <Item title="Zaposleni" content={dashboardInfo?.employeesCount} />
          </div>
          <div className="w-1/4 max-lg:w-full ">
            <Item title="Dokumenta" content={5} />
          </div>
          <div className="w-1/4 max-lg:w-full ">
            <Item title="Zadaci koji zahtevaju Vašu pažnju" content={10} />
          </div>
        </div>
      </div>

      {/* Second row */}

      <div className="flex flex-row w-full h-full p-3 max-lg:p-1 max-lg:flex-col">
        <div className="flex w-3/5 border border-gray-200 rounded-lg shadow bg-primary-55 shoradow max-lg:w-full dark:bg-primary-800 dark:border-gray-700">
          <Activity completedTasksData={completedTasksData} />
        </div>
        <div className="w-2/5 max-lg:pt-4 lg:pl-8 max-lg:w-full ">
          <div className="w-full h-full border border-gray-200 rounded-lg shadow bg-primary-55 dark:bg-primary-800 dark:border-gray-700">
            <div className="flex flex-col p-6">
              <span className="mb-2 font-medium">What is Lorem Ipsum?</span>
              <span className="mb-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </span>
              <span className="mb-2 font-medium">What is Lorem Ipsum?</span>
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6">
        {/* <Table
          headers={headers}
          data={data}
          buttons={buttons}
          headerNames={headerNames}
          role={userRole}
          searchText="listu zaposlenih"
          tableTitle="Članovi moje organizacije"
        /> */}

        <button className="p-4 bg-red-400" onClick={() => saljiPodatke()}>
          Salji za testiraje
        </button>
      </div>
    </div>
  );
};

export default MyDashboard;
