import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import ControlDetails from "./compliance/controls/ControlDetails";

const ContentSideBar = ({ handleClose, isSelected }) => {
  const [isClosing, setIsClosing] = useState(false);

  //animation for closing contentSideBar
  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(handleClose, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [isClosing, handleClose]);

  const handleCloseWithAnimation = () => {
    setIsClosing(true);
  };
  //const domNode = useClickOutsideDesktop(handleCloseWithAnimation);

  return (
    <>
      {isSelected && (
        <div
          className={`absolute block z-40 top-0 h-full right-0 xl:w-9/12 bg-gray-50 dark:bg-primary-800 animate-slide-in-right md:w-full w-full  ${
            isClosing && "animate-close-content-side-bar"
          }`}
        >
          <div className="absolute z-30 block w-6 h-6 rounded-full right-2 lg:visible top-2 hover:cursor-pointer">
            <XMarkIcon onClick={handleCloseWithAnimation}></XMarkIcon>
          </div>
          <ControlDetails control={isSelected} />
        </div>
      )}
    </>
  );
};
export default ContentSideBar;
