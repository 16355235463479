import React from "react";

const ProgressBar = ({ percentage }) => {
  return (
    <div className="w-full overflow-hidden bg-gray-200 rounded-lg">
      <div
        className="py-1 text-xs leading-none text-center text-white dark:bg-blue-500 bg-primary-800"
        style={{ width: `${percentage}%` }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default ProgressBar;
