import React from "react";
import Login from "./pages/Login/Login";
import ResetPasswordEmail from "./pages/Login/ResetPasswordEmail";
import ResetPassword from "./pages/Login/ResetPassword";
import Dashboard from "./pages/Dashoard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from "./history";
import { ModalProvider } from "./context/ModalContext";
import ErrorCodePage from "./pages/ErrorCodePage";
import Home from "./pages/Home/Home";
import { LoadingProvider } from "./context/LoadingContext";
import PrivateRoute from "./components/PrivateRoute";
import { PageTitleProvider } from "./context/PageTitleContext";
const App = () => {
  return (
    <BrowserRouter history={history}>
      <ModalProvider>
        <LoadingProvider>
          <PageTitleProvider>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/dashboard/mystandard"
                  element={<Dashboard model="standards" />}
                />

                {/* Routes for frameworks */}
                <Route
                  path="/framework-details/:id"
                  element={<Dashboard model="framework-details" />}
                />

                <Route
                  path="/law-details/:id"
                  element={<Dashboard model="law-details" />}
                />

                <Route
                  path="/standard-details/:id"
                  element={<Dashboard model="standard-details" />}
                />

                <Route
                  path="/:type-details/:type-requirements/:id"
                  element={<Dashboard model="requirements" />}
                />
                <Route
                  path="/:type-details/:type-control/:id"
                  element={<Dashboard model="control" />}
                />
                <Route
                  path="/:type-details/:type-requirements/requirement-control/:id/:tid"
                  element={<Dashboard model="control-details" />}
                />
                <Route
                  path="/dashboard/employees"
                  element={<Dashboard model="employees" />}
                />

                {/* Routes for laws */}
                <Route
                  path="/dashboard/mylaws"
                  element={<Dashboard model="laws" />}
                />
                <Route
                  path="/dashboard/documents"
                  element={<Dashboard model="documents" />}
                />

                <Route
                  path="/my-profile"
                  element={<Dashboard model="my-profile" />}
                />
                <Route
                  path="/dashboard/my-dashboard"
                  element={<Dashboard model="my-dashboard" />}
                />
                <Route
                  path="/dashboard/my-organization"
                  element={<Dashboard model="my-organization" />}
                />
                <Route
                  path="/dashboard/settings"
                  element={<Dashboard model="settingspanel" />}
                />
                <Route
                  path="/dashboard/security-settings"
                  element={<Dashboard model="security-settings" />}
                />
                <Route path="/" element={<Dashboard model="my-dashboard" />} />
              </Route>
              <Route path="/home" element={<Home />} />
              <Route path="/auth/login" element={<Login />} />
              <Route
                path="/reset-password-1"
                element={<ResetPasswordEmail />}
              />
              <Route path="/reset-password-2" element={<ResetPassword />} />
              <Route path="/forbidden" element={<ErrorCodePage code="403" />} />
              {/* This route will match any route that hasn't been matched by the above routes */}
              <Route path="*" element={<ErrorCodePage code="404" />} />
            </Routes>
          </PageTitleProvider>
        </LoadingProvider>
      </ModalProvider>
    </BrowserRouter>
  );
};

export default App;

//https://colorhunt.co/palette/f9f7f7dbe2ef3f72af112d4e, https://colorhunt.co/palette/1b262c0f4c753282b8bbe1fa
//hello
