import React, { useEffect } from "react";
import { useModal } from "../../context/ModalContext";
import { useSelector } from "react-redux";
import { useState } from "react";
import { handleClick, truncate } from "../../utils/helpers/helperFunctions";

const InfoCard = ({ data, type }) => {
  const [updatedData, setData] = useState(data);
  console.log("Data");
  console.log(data);
  const { openModal, closeModal } = useModal();

  // If showAll is true, display all elements of the array; otherwise, display only the first five elements.

  if (!data) {
    return <h1>Loading...</h1>;
  }

  const { longDesc, shortDesc, isExtended, id, title } = data;

  return (
    <div className="min-h-full rounded-lg shadow bg-primary-55 dark:bg-primary-800 dark:border-gray-700">
      <div className="p-5">
        <div>
          <p className="py-1 mb-8 max-lg:text-lg max-md:text-base">
            <span className="font-bold max-lg:text-base max-md:text-sm">
              {title}
            </span>
            <br />
            <span className="font-light max-lg:text-base max-md:text-sm">
              <p className="mt-2">{longDesc}</p>

              {/* <p onClick={() => handleClick(id, updatedData, setData)}>
                {isExtended ? longDesc : shortDesc}
              </p> */}
            </span>
          </p>
        </div>

        <p className="py-1 font-normal text-gray-700 dark:text-gray-400"></p>
        {/* Show admin button only if role is equal to admin */}
        {/* {roleName === "admin" && (
          <button
            onClick={() =>
              openModal("Modal3", {
                content: [data],
                close: closeModal,
                type: type,
              })
            }
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-750 hover:bg-gray-700 focus:ring-4 focus:outline-none dark:bg-primary-750 dark:hover:bg-gray-700 "
          >
            Izmeni
            <svg
              className="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        )} */}
      </div>
    </div>
  );
};

export default InfoCard;
