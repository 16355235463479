import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

const EmployeesSideBar = ({ handleClose, data }) => {
  const [isClosing, setIsClosing] = useState(false);

  //animation for closing contentSideBar
  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(handleClose, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [isClosing, handleClose]);

  const handleCloseWithAnimation = () => {
    setIsClosing(true);
  };

  // func to show html code
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <>
      <div
        className={`absolute block z-40 top-0 h-full right-0 xl:w-1/2 bg-gray-50 border dark:bg-primary-800 animate-slide-in-right md:w-full w-full overflow-y-auto  ${
          isClosing && "animate-close-content-side-bar"
        }`}
      >
        <div className="absolute z-30 block w-6 h-6 rounded-full right-2 lg:visible top-2">
          <XMarkIcon onClick={handleCloseWithAnimation}></XMarkIcon>
        </div>
        <div className="p-10 mt-12">
          <div className="px-12 py-6 rounded-md shadow-md">
            <p className="mt-2 font-semibold ">Naziv</p>
            <p className="text-sm">{data.name}</p>
            <p className="mt-2 font-semibold ">Autor</p>
            <p className="text-sm">{data.author}</p>
            <p className="mt-2 font-semibold ">Datum</p>
            <p className="text-sm">{data.datePublished}</p>
            <p className="mt-2 font-semibold ">Tip</p>
            <p className="text-sm">{data.type}</p>
          </div>

          <div className="px-12 py-6  mt-8 rounded-md shadow-md ">
            <p className="mb-3 font-semibold">Kratak opis</p>
            <div
              dangerouslySetInnerHTML={createMarkup(data?.shortDescription)}
              className="text-sm"
            />
            <p className="mt-4 mb-3 font-semibold">Opis</p>
            <div
              dangerouslySetInnerHTML={createMarkup(data?.longDesc)}
              className="text-sm"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeesSideBar;
