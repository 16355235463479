import React from "react";
import { truncate } from "../../../utils/helpers/helperFunctions";

const TableRecursiveRender = ({ data, elem }) => {
  // Function to recursively render JSON data in table format
  const renderData = (item) => {
    // If item is an array, render its elements in table rows
    if (Array.isArray(item)) {
      return (
        <>
          <div className="flex justify-center">
            <td className="py-6 text-center">{item[0][elem]}</td>
            <button onClick={() => item.map((el) => console.log(el[elem]))}>
              {item.length - 1 > 0 ? <span>+ {item.length - 1}</span> : null}
            </button>
          </div>
        </>
      );
    }
    // If item is neither an array nor an object, render it as a table cell
    else {
      return (
        <td
          className={`py-6 font-light text-center text-primary-800 dark:text-gray-300 `}
        >
          {item}
        </td>
      );
    }
  };

  return <>{renderData(data)}</>;
};

export default TableRecursiveRender;
