import React, { useContext } from "react";
import Switcher from "../ThemeSwitcher";
import { BellIcon } from "@heroicons/react/24/outline";
import { useSidebar } from "../../context/SidebarContext";
import { useSelector } from "react-redux";
import { PageTitleContext } from "../../context/PageTitleContext";
import placeholderMan from "../../assets/images/placeholderMan.jpg";
const DashboardNavBar = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  //Accessing Redux global state to retrive data
  const { roleName: userRole } = useSelector((state) => state.auth.user?.role);
  const firstName = useSelector((state) => state.auth.user?.firstName);
  const lastName = useSelector((state) => state.auth.user?.lastName);
  const { pageTitle } = useContext(PageTitleContext);

  return (
    <nav className="sticky top-0 z-10 flex w-full h-full px-4 bg-primary-54 text-primary-750 dark:text-white border-opacity-20 dark:bg-primary-750">
      <div className="flex justify-between w-full h-full px-4 py-4 ">
        {/* Hamburger menu which is shon in case that state "mobileMenu" is set to TRUE value */}

        <div className="flex items-center">
          <span className="ml-4 font-bold lg:text-2xl md:ml-4 sm:ml-4 ">
            {pageTitle}
          </span>
          <button
            data-collapse-toggle="navbar-solid-bg"
            type="button"
            class={`${
              isSidebarOpen ? "hidden" : "flex items-center "
            } absolute  z-20  left-3  w-10 h-full justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"`}
            aria-controls="navbar-solid-bg  "
            aria-expanded="false"
            onClick={(e) => {
              e.preventDefault();
              toggleSidebar();
            }}
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        <div className="flex items-center ">
          <span className="w-7 max-sm:w-5 text-primary-800 dark:text-white ">
            <BellIcon />
          </span>
          <span className="ml-3 mr-4 ">
            <Switcher />
          </span>
          <div className="flex rounded-full ">
            <div className="flex flex-col justify-center lg:mr-2 ">
              <span>
                {firstName} {lastName}
              </span>
              <span className="text-sm italic font-thin">{userRole}</span>
            </div>
            <div className="lg:flex">
              <span className="hidden w-12 h-12 lg:flex ">
                <img src={placeholderMan} alt="User" className="rounded-full" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavBar;
