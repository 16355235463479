import apiService from "./apiService";

//GET
const addControl = async (data) => {
  const response = await apiService.postData("controls", data);
  return response;
};

//GET control by id
const getById = async (data) => {
  const { id } = data;
  const response = await apiService.getData(`controls/${id}`);
  return response;
};
//get tasks for controls
const getTasksByControlId = async (data) => {
  const { id } = data;
  const response = await apiService.getData(`controls/${id}/tasks`);
  return response?.data?.tasks;
};
//change control status, using dropDown menu
const changeStatus = async (data) => {
  const parsed = { id: data.data.id, status: data.data.values?.status };
  console.log(parsed);
  const response = await apiService.putData("controls/status-change", parsed);
  return response;
};

//change control owner, using DropDown menu
const changeOwner = async (id, data) => {
  const response = await apiService.putData(`controls/${id}/assignee-change`, {
    newAssignees: data.data[0],
    removeAssignees: data.data[1],
  });
  return response;
};

// add control owner if it is not defined
const addOwner = async (id, data) => {
  const response = await apiService.putData(
    `controls/${id}/assignee-change`,
    data
  );
  return response;
};

//enable-disable control using toggleButton
const toggleStatus = async (id, data) => {
  const response = await apiService.putData(`controls/${id}/toggle`, {
    rationale: data,
  });
  return response;
};

//delete control
const deleteControl = async (id) => {
  const response = await apiService.deleteData(`controls/${id}`);
  return response.data.controls;
};

//GET
const saljiTest = async (data) => {
  const response = await apiService.postData(
    "health/test/decodeSentData",
    data
  );
  return response;
};

export const controlService = {
  addControl,
  changeStatus,
  getById,
  getTasksByControlId,
  changeOwner,
  addOwner,
  toggleStatus,
  deleteControl,
  saljiTest,
};
