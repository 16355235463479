import React from "react";
import Laws from "../dashboard_components/compliance/laws/Laws";
import Frameworks from "./compliance/frameworks/Frameworks";
import DashboardNavBar from "./DashboardNavbar";
import ErrorCodePage from "../../pages/ErrorCodePage";
// import TaskDetails from "./tasks/TaskDetails";
import MyProfile from "../my_profile/MyProfile";
import MyDashboard from "./my_dashboard/MyDashboard";
import { useSelector } from "react-redux";
// import AllTasks from "./tasks/AllTasks";
import SettingsPanel from "./settings_component/SettingsPanel";
import Requirements from "./compliance/Requirements";

import ControlDetails from "./compliance/controls/ControlDetails";
import ComplianceElementDetails from "./compliance/ComplianceElementDetails";
import SecuritySettings from "./settings_component/SecuritySettings";
import Documents from "./compliance/Documents";
import FrameworkDetails from "./compliance/frameworks/FrameworkDetails";
import LawDetails from "./compliance/laws/LawDetails";
import Strandards from "./compliance/standards/Standards";
import StandardDetails from "./compliance/standards/StandardDetails";
import People from "./Employees";
import Employees from "./Employees";

const DashboardPanel = ({ model }) => {
  //Render content for MAIN panel based on model which is being passed
  const { roleName: userRole } = useSelector((state) => state.auth.user?.role);

  const renderContent = () => {
    switch (model) {
      case "my-dashboard":
        return userRole === "admin" ? (
          <MyDashboard />
        ) : (
          <ErrorCodePage code="403" />
        );
      // --------------------------------- Complience components section ---------------------------------
      case "frameworks":
        return userRole === "admin" ? (
          <Frameworks />
        ) : (
          <ErrorCodePage code="403" />
        );
      case "laws":
        return userRole === "admin" || userRole === "user" ? (
          <Laws />
        ) : (
          <ErrorCodePage code="403" />
        );

      case "standards":
        return <Strandards />;
      case "compliance-element-details":
        return <ComplianceElementDetails />;
      case "standard-details":
        return <StandardDetails />;
      case "framework-details":
        return <FrameworkDetails />;
      case "law-details":
        return <LawDetails />;
      case "requirements":
        return <Requirements />;
      case "control-details":
        return <ControlDetails />;
      case "documents":
        return <Documents />;
      case "employees":
        return <Employees />;

      // --------------------------------- Settings component section ---------------------------------
      case "my-profile":
        return <MyProfile />;
      case "security-settings":
        return <SecuritySettings />;
      case "settingspanel":
        return <SettingsPanel />;

      default:
        return <ErrorCodePage code="404" />;
    }
  };

  return (
    <div
      id="dashboardPanel"
      className="relative flex flex-col w-full h-full min-h-screen overflow-x-hidden bg-primary-54 dark:bg-primary-750 text-primary-800 dark:text-white "
    >
      <DashboardNavBar />
      {/* {content} */}
      {renderContent()}
    </div>
  );
};

export default DashboardPanel;
