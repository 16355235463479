import {
  encryptAesKeyWithRsa,
  encryptFileWithAes,
  generateAesKey,
} from "../../utils/generateKey";
import { formatDate } from "../../utils/helpers/formatDate";
import apiService from "./apiService";

//post file to create task
const uploadFile = async (data) => {
  let formData = new FormData();
  const aesKey = await generateAesKey();
  const publicKey = localStorage.getItem("serverPubKey");

  const { id, file, name, description, assignees, status, startDate, endDate } =
    data;

  if (data.file) {
    const encryptedFile = await encryptFileWithAes(file, aesKey);
    console.log("enccc file", encryptedFile);
    formData.append("file", encryptedFile);
  }
  if (name) {
    formData.append("title", name);
  }

  if (description) {
    formData.append("description", description);
  }

  if (assignees) {
    formData.append("assignees", assignees);
  }
  if (status) {
    formData.append("status", status);
  }
  if (startDate) {
    const formattedStartDate = formatDate(new Date(startDate));
    formData.append("startDate", formattedStartDate);
  }

  if (endDate) {
    const formattedEndDate = formatDate(new Date(endDate));
    formData.append("endDate", formattedEndDate);
  }
  const encryptedAesKey = await encryptAesKeyWithRsa(aesKey, publicKey);
  formData.append("key", encryptedAesKey);
  const response = await apiService.postFormData(
    `controls/173/tasks`,
    formData
  );

  return response;
};

export const filesService = {
  uploadFile,
};
