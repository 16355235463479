// Table.jsx
import React, { useEffect, useState } from "react";
import SearchBar from "../SearchBar";
import Pagination from "../Pagination";
import PopupComment from "../../modals/PopupComment";
import ModalDetails from "../../modals/ModalDetails";
import { Dropdown } from "../FormComponents";
import { useDispatch, useSelector } from "react-redux";
import { changeTaskStatus, getTaskById } from "../../../api/slices/taskSlice";

const Table = ({
  headers,
  headerNames,
  data,
  buttons,
  role,
  tableTitle,
  itemsPerPage = 5,
  detailsButton,
  disabled,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter data based on search query
  const filteredData = data?.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setCurrentPage(1); // Reset to the first page when the search query changes
    setSearchQuery(event.target.value);
  };
  //open ModalDetails for small resolution
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const openModal = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };
  //

  //-----Zasto ovde proveravamo velicinu ekrana------?
  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth <= 1400
  );
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const { tasksData, loadingTask } = useSelector(
    (state) => state.tasksForControl
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth <= 1400);
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  --------------------

  const [selectedOption, setSelectedOption] = useState("");
  const options = [
    { value: "Not started", label: "Not started" },
    { value: "In progress", label: "In progress" },
    { value: "For review", label: "For review" },
    { value: "Finished", label: "Finished" },
  ];

  //change task status using dropDown menu
  const handleChange = (event, itemId) => {
    const newValue = event.target.value;
    setSelectedOption((prev) => ({ ...prev, [itemId]: newValue }));
    dispatch(changeTaskStatus({ id: itemId, status: newValue }));
  };

  return (
    <div className="z-0 sm:rounded-lg">
      <div className="flex items-center py-4 lg:justify-end">
        <span className="ml-1 font-medium text-md md:text-xl">
          {tableTitle}
        </span>
        <SearchBar
          disabled={disabled}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          type="search"
          searchBy={["Imenu", "Broju zadataka"]}
          position="left"
        />
      </div>

      <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase border border-gray-100 rounded-lg dark:border-gray-700 bg-primary-50 dark:bg-primary-750 dark:text-gray-400">
          <tr>
            {headers?.map((header, index) => {
              // if (isSmallScreen && header !== "id" && header !== "name") {
              //   return null; // Skip rendering this header on small screens
              // } else if (
              //   isMediumScreen &&
              //   !isSmallScreen &&
              //   header !== "id" &&
              //   header !== "name" &&
              //   header !== "description"
              // ) {
              //   return null; // Skip rendering this header on medium screens
              // }

              //   return (
              //     <th key={index} className="px-6 py-3">
              //       {headerNames[index]}
              //     </th>
              //   );
              // })}
              //====================================================ovo je noovoo==================================================================
              return (
                <th
                  key={index}
                  className={`px-6 py-3 ${
                    header === "id" || header === "name"
                      ? ""
                      : header === "description"
                      ? "hidden md:table-cell"
                      : "hidden lg:table-cell"
                  }`}
                >
                  {headerNames[index]}
                </th>
              );
            })}
            {/* //====================================================ovo je noovoo================================================================== */}
            {(role === "admin" || role === "user") &&
              buttons &&
              buttons.length > 0 && <th className="px-6 py-3">Akcije</th>}
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item, rowIndex) => (
            <tr
              key={rowIndex}
              className="border-b bg-primary-50 dark:bg-primary-750 dark:border-primary-800"
            >
              {headers.map((header, colIndex) => {
                if (header === "assignees") {
                  return (
                    <td
                      key={colIndex}
                      className="px-6 py-4 font-light text-primary-800 lg:whitespace-nowrap dark:text-gray-300"
                    >
                      {item[header]?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person.firstName} {person.lastName}
                          {index < item[header]?.length - 1 && ", " && (
                            <br></br>
                          )}
                        </React.Fragment>
                      ))}
                    </td>
                  );
                } else if (header === "status") {
                  return (
                    <td
                      key={colIndex}
                      className="hidden font-light text-primary-800 dark:text-gray-300 sm:table-cell"
                    >
                      <div>
                        <Dropdown
                          options={options}
                          onChange={(e) => handleChange(e, item.id)}
                          value={selectedOption[item.id] || item.status}
                          placeholder="Please select an option"
                          text={item["status"]}
                          id={item["id"]}
                        />
                      </div>
                    </td>
                  );
                }

                // if (isSmallScreen && header !== "id" && header !== "name") {
                //   return null; // Skip rendering this column on small screens
                // } else if (
                //   isMediumScreen &&
                //   !isSmallScreen &&
                //   header !== "id" &&
                //   header !== "name" &&
                //   header !== "description"
                // ) {
                //   return null; // Skip rendering this cell on medium screens
                // }
                //   return (
                //     <td
                //       key={colIndex}
                //       className="px-6 py-4 font-light text-primary-800 whitespace-nowrap dark:text-gray-300"
                //     >
                //       {item[header]}
                //     </td>
                //   );
                // })}

                return (
                  <td
                    key={colIndex}
                    className={`px-2 py-4 font-light text-primary-800 whitespace-wrap dark:text-gray-300 text-center ${
                      header === "id" || header === "name"
                        ? ""
                        : header === "description"
                        ? "hidden md:table-cell"
                        : "hidden lg:table-cell"
                    }`}
                  >
                    <span>{item[header]}</span>
                  </td>
                );
              })}

              <td className="flex items-center px-6 py-4 ">
                {buttons.map((button, buttonIndex) => (
                  <>
                    {button.type === "icon-document" ? (
                      <button
                        key={buttonIndex}
                        className={`font-medium ${button.type} text-white px-4 py-2 rounded-md flex-row-reverse mt-1`}
                        onClick={() =>
                          button.onClick(
                            dispatch(getTaskById({ id: item["id"] }))
                          )
                        }
                      >
                        {button.label}
                      </button>
                    ) : button.label === "comment" ? (
                      <PopupComment item={item} />
                    ) : (
                      <button
                        key={buttonIndex}
                        className={`font-medium ${button.type} text-white px-4 py-2 rounded-md flex-row-reverse mt-1`}
                        onClick={() => button.onClick(item)}
                      >
                        {button.label}
                      </button>
                    )}
                  </>
                ))}

                {(isSmallScreen || isMediumScreen) && (
                  <td>{detailsButton(item, openModal)}</td>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <ModalDetails isOpen={isModalOpen} onClose={closeModal}>
          <div className="flex flex-col ml-2 space-y-2 text-sm">
            {selectedRow &&
              Object.entries(selectedRow).map(([key, value], index) => (
                <div key={index}>
                  <strong>{headerNames[index]}:</strong>
                </div>
              ))}
          </div>
        </ModalDetails>
      )}
      {filteredData?.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Table;
