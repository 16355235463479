import React, { useEffect } from "react";
import ListOfElements from "../../../common/ListOfElements";
import { useDispatch, useSelector } from "react-redux";
import { getListOfLaws } from "../../../../api/slices/lawsSlice";
import notfoundImg from "../../../../assets/images/notfound.png";

const Laws = () => {
  const dispatch = useDispatch();
  const { listOfLaws } = useSelector((state) => state.laws);

  useEffect(() => {
    if (!listOfLaws) {
      dispatch(getListOfLaws());
    }
  }, [dispatch, listOfLaws]);

  if (!listOfLaws) {
    return <div>Loading...</div>; // Loading state
  }

  if (listOfLaws.length === 0) {
    return (
      <div class="flex items-center justify-center h-screen bg-gray-100">
        <img src={notfoundImg} alt="Description of the image" />
      </div>
    );
  }

  return <ListOfElements elements={listOfLaws} typeName={"Zakoni"} />;
};

export default Laws;
